<template>
    <div class="body-container">
        <BannerSection
                       title="¿Necesitas préstamos personales urgentes? <br class='d-none d-lg-block'/>Así puedes conseguirlo " />
        <PerksComponent></PerksComponent>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <h1 class="bold-text"
                        style="font-size: 25px;">Préstamos personales urgentes: Todo lo que debes saber</h1>
                    <figure class="image-container">
                        <img :src="require(`@/assets/img/hombrePobre.png`)"
                             alt="persona haciendo calculos"
                             class="img-fluid">
                    </figure>
                    <p class="mb-0">
                        ¿Te ha surgido un gasto imprevisto o necesitas cubrir una emergencia financiera rápidamente? Los
                        <b>préstamos personales urgentes</b> pueden ser la solución que buscas.
                    </p>
                    <br>
                    <p>Este tipo de financiamiento ha sido diseñado para aquellos momentos en los que necesitas dinero
                        de forma rápida y eficiente. Aquí te explicamos cómo funcionan, sus beneficios, requisitos y
                        cómo puedes obtener uno.
                    </p>
                    <p></p>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">¿Cómo funcionan los préstamos personales urgentes?
                        </h2>
                       
                    </span>

                    <p>
                        Estos préstamos son créditos de respuesta rápida, con o sin garantía. Los que son sin garantía
                        pueden no ser tan rápidos y requerir mayores exigencias.
                    </p>
                    <p>
                        Los que son con garanía pueden utilizar distintos tipos de aval para asegurar el préstamo. En al
                        caso de Ibancar, puedes utilizar tu coche como garantía, pero sin necesidad de entregarlo, ni
                        dejar de usarlo. Solo debes demostrar la propiedad del vehículo y cumplir algunos requisitos
                    </p>

                    <br>
                    <span class="bold-text">
                        <h2 style="font-size: 20px;">Ventajas de los préstamos personales urgentes</h2>
                    </span>
                    <p>Optar por un préstamo personal urgente ofrece múltiples beneficios: </p>
                    <ul>
                        <li>
                            <p class="mb-0 bold-text">
                                Rapidez:
                            </p>
                            <span>puedes obtener tu préstamo en menos de 48 horas, por lo que son ideales para esos
                                momentos de necesidad inmediata.</span>
                        </li>

                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Sin necesidad de cambio de titularidad:
                            </p>
                            <span>continúas siendo el propietario de tu vehículo, así que no tienes de qué preocuparte.
                            </span>
                        </li>
                        <br>

                        <li>
                            <span>Proceso completamente en línea, lo que facilita el trámite.</span>
                        </li>

                    </ul>

                    <br>
                </div>
            </div>
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Requisitos para solicitar un préstamo personal urgente</h2>
                        
                    </span>
                    <p>Los requisitos son accesibles para prácticamente cualquier persona. Solo necesitas:</p>
                    <br>
                    <ul>
                        <li>
                            <p class="mb-0">
                                <span class="">Ser mayor de edad, propietario y titular del vehículo.
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Poseer la factura original del coche.
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Tener el vehículo en buen estado y con los documentos en regla.</span>
                            </p>
                        </li>
                        <li>
                            <p class="mb-0">
                                <span class="">Presentar identificación oficial (INE), comprobante de ingresos y de
                                    domicilio.</span>
                            </p>
                        </li>
                        <br>
                    </ul>
                    <br>
                </div>

            </div>

        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <h2 class="bold-text"
                        style="font-size: 20px;">¿Cómo obtener tu préstamo personal urgente en 48 horas?</h2>
                   
                    <p>El proceso es sencillo y se realiza completamente en línea. Estos son los pasos a seguir: </p>

                    <ul>
                        <li>
                            <p class="mb-0">
                                <span class="">Completa la solicitud online con tus datos y los del vehículo.
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Espera la propuesta personalizada con las especificaciones del préstamo y
                                    las condiciones.
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Envía la documentación requerida. Además de los requisitos anteriores,
                                    debes tener lo siguiente: foto tipo selfie con INE, fotografías del vehículo y
                                    tarjeta de circulación vigente.
                                </span>

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="">Una vez aprobado el préstamo recibiras el dinero directamente en tu
                                    cuenta.</span>

                            </p>
                        </li>
                        <br>
                    </ul>
                    <br>

                </div>

            </div>

        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">

                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Proceso 100% online para préstamos personales urgentes
                        </h2>
                        <br>
                    </span>
                    <figure class="image-container">
                        <img :src="require(`@/assets/img/mujerTelefono.png`)"
                             alt="persona haciendo calculos"
                             class="img-fluid">
                    </figure>

                    <p>Todo el proceso, desde la solicitud hasta la aprobación y transferencia del dinero, se realiza en
                        línea. Esto te permite gestionar tu préstamo desde cualquier lugar y a cualquier hora y te
                        ofrece una solución rápida y sin necesidad de desplazamientos.</p>
                    <br>
                </div>
            </div>
        </div>

        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <br>
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Preguntas frecuentes sobre los préstamos personales urgentes</h2>
                        
                    </span>
                    <div>
                        <h3 style="font-size: 20px; font-style: italic;">- ¿Puedo seguir utilizando mi coche mientras pago el préstamo?</h3>
                        <p style="font-size: 17px;">Sí, con Ibancar utilizas tu auto como garantía sin necesidad de entregarlo, limitaciones, ni GPS.  </p>
                    </div>
                    <br>
                    <div>
                        <h3 style="font-size: 20px; font-style: italic;">- ¿Cuál es el plazo para pagar el préstamo?</h3>
                        <p style="font-size: 17px;">Los plazos pueden variar, pero generalmente son flexibles para adaptarse a tus necesidades, aportando a capital desde el minuto 1.  </p>
                    </div>
                    <br>
                    <div>
                        <h3 style="font-size: 20px; font-style: italic;">-  ¿Qué pasa si pago antes de tiempo?</h3>
                        <p style="font-size: 17px;">No hay penalizaciones por pagos anticipados, lo que te permite liquidar tu préstamo cuando lo desees sin costes adicionales. </p>
                    </div>
                    <br>

                    <h2 style="font-size: 20px;"
                        class="bold-text">Contacta con nosotros para tu préstamo personal urgente</h2>
                    
                    <p>Si necesitas un préstamo personal urgente y quieres mantener la libertad de seguir usando tu coche, <a href="https://ibancar.mx/contacto">Ibancar</a> es tu mejor opción.  </p>
                    <p>Te ofrecemos un servicio rápido y personalizado, garantizando tu satisfacción en cada paso del proceso.  </p>

                    <p>No dejes que una necesidad urgente perturbe tu tranquilidad. Opta por un préstamo personal urgente con Ibancar y soluciona tus problemas financieros hoy mismo.</p>
                </div>

            </div>

        </div>
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamosPersonalesUrgentes',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Préstamos personales urgentes  | Ibancar',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Préstamos personales urgentes con tu coche como aval. Recibe efectivo rápido sin revisión de buró de crédito y con requisitos mínimos. ¡Solución inmediata!'
                    },
                    {
                        name: 'keywords',
                        content: 'prestamos personales urgentes'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/empeno-facil', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.image-container {
    text-align: center;
    margin: 3rem 0rem;

    img {
        max-height: 400px;
    }
}

.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>